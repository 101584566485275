import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {UserList} from "../pages/admin/users/UserList";

const AppAdminRoutes = () => {
    return (
        <Routes>
            <Route path="/users" element={<UserList/>}/>
        </Routes>
    );
}

export default AppAdminRoutes;