import {AuthLogo} from "../../components/AuthLogo";
import {Button, Col, Form, Input, Row} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "./auth.css";
import React from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {UserContext} from "../../utils/UserContext";

export function Register() {
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();
    const { setUser } = React.useContext(UserContext);
    const onFinish = (values) => {
        setError(null);
        AuthService.register(values).then(response => {
            if(response.status) {
                localStorage.setItem('user', JSON.stringify(response.data));
                setUser(response.data);
                navigate('/onboarding');
            }
            else{
                setError(response.message);
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <div className="auth-container">
            <Row justify="center" align="middle">
                <Col lg={10} md={16} sm={24}>
                    <div className="auth-card gradient-border">
                        <AuthLogo/>
                        <h2>Signup</h2>
                        <Form
                            layout="vertical"
                            requiredMark={false}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                hasFeedback
                                label="Name"
                                name="name"
                                rules={[{required: true, message: 'Please enter your name!'}]}
                            >
                                <Input placeholder="Name" rootClassName="form-control"/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label="School/University"
                                name="school"
                                rules={[{required: true, message: 'Please enter your school/university!'}]}
                            >
                                <Input placeholder="School/University" rootClassName="form-control"/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Please enter your email!'}]}
                            >
                                <Input type="email" placeholder="Email" rootClassName="form-control"/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label="Password"
                                name="password"
                                rules={[{required: true, message: 'Please enter your password!'}]}
                            >
                                <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Password" rootClassName="form-control"/>
                            </Form.Item>

                            <Button htmlType="submit" type="submit">Signup</Button>
                            {error && <p className="text-danger fs-14 fw-800 text-center">{error}</p>}
                        </Form>
                        <p className="text-white fs-14 fw-800 text-center">Already have account? <a href="/login" className="text-white">Login</a></p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}