import "./layout.css";
import {Col, Layout, Row} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import React, {useContext} from "react";
import {Sidebar} from "./Sidebar";
import {EnterSimulation} from "../EnterSimulation";
import {UserContext} from "../../utils/UserContext";
import {Outlet} from "react-router-dom";


export function DashboardLayout() {
    const { user } = useContext(UserContext);
    return (
        <Layout className={"app-container"}>
            <Sidebar/>
            <Layout className={"main-content"}>
                <Header>
                    <Row justify={"space-between"} align={"middle"}>
                        <Col span={11} lg={12} md={16} xs={24}>
                            <EnterSimulation />
                        </Col>
                        <Col>
                            <div className={"user-profile"}>
                                <h6>{user.full_name}</h6>
                                <img src={"assets/images/dummy-profile.jpg"} className={"ml-10"} />
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <div>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}