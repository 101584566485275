import moment from "moment";
import {useContext} from "react";
import {UserContext} from "../utils/UserContext";
import {Col, Row} from "antd";

export function EnterSimulation(){
    const { user } = useContext(UserContext);
    let date = moment().format('DD MMM, YYYY');
    return (<Row className={"enter-simulation"} align={"middle"}>
        <Col span={14} lg={14} md={12} xs={24}>
            <p>{date}</p>
            <h2>Welcome User {user.full_name}</h2>
        </Col>
        <Col span={10} lg={10} md={12} xs={24}>
            <a className={"btn-primary d-block"} href={"/onboarding"}>
                Enter Simulation
                <img src={"/assets/images/caret-right.svg"} className={"ml-10"} />
            </a>
        </Col>
    </Row>)
}