import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from "../pages/auth/Login";
import {Register} from "../pages/auth/Register";
import {ForgotPassword} from "../pages/auth/ForgotPassword";
import {Dashboard} from "../pages/Dashboard/Dashboard";
import AppAdminRoutes from "./admin";
import UserMiddleware from "../middlewares/UserMiddleware";
import {Onboarding} from "../pages/Onboarding/Onboarding";
import {DashboardLayout} from "../components/layouts/DashboardLayout";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />

            <Route element={<UserMiddleware component={DashboardLayout} isProtectedRoute={true} />}>
                <Route path="/dashboard" element={<Dashboard />} />
            </Route>

            <Route path="/onboarding" element={<UserMiddleware component={Onboarding} isProtectedRoute={true} />} />

            <Route path="/admin/*" element={<AppAdminRoutes />} />

            <Route path="*" element={<Login/>} />
        </Routes>
    );
}

export default AppRoutes;