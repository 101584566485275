import AppRoutes from "./routes";
import React from "react";
import "./global.css";
import {ConfigProvider} from "antd";
import {UserProvider} from "./utils/UserProvider";

export function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: 'Gilroy, sans-serif',
                },
            }}
        >
            <div className="App">
                <UserProvider>
                    <AppRoutes/>
                </UserProvider>
            </div>
        </ConfigProvider>
    )
}