import {Calendar, Card, Col, List, Radio, Row, Skeleton, Table} from "antd";
import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

export function Dashboard() {
    let series =  [{
        name: 'Expense',
        data: [150, 144, 76, 135, 59, 63, 100]
    }, {
        name: 'Income',
        data: [189, 80, 120, 54, 96, 102, 123]
    }];

    let chart = {
        colors:["#5A55D2", "#00DEA3"],
        fill: {
            type: 'gradient',
            gradient: {
                type: "vertical",
                opacityFrom: 0.6,
                opacityTo: 0,
                stops: [0, 80],
            }
        },
        chart: {
            toolbar:{
                show: false,
            },
            height: "100%",
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'monotoneCubic'
        },
        yaxis:{
            show: false,
        },
        grid:{
            show:false,
        },
        legend:{
            horizontalAlign: "right"
        },
        xaxis: {
            show: false,
            labels: {
                show: true
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            type: 'datetime',
            categories: ["2024-07-28T00:00:00.000Z", "2024-07-28T01:30:00.000Z", "2024-07-28T02:30:00.000Z", "2024-07-28T03:30:00.000Z", "2024-07-28T04:30:00.000Z", "2024-07-28T05:30:00.000Z", "2024-07-28T06:30:00.000Z"]
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return "$" + value
                }
            }
        },
    };
    const columns = [
        {
            title: 'Icon',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                <img src={record.type === "sale" ? "/assets/images/income-icon.png" : "/assets/images/expense-icon.png"} />
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                record.type === "sale" ? "Income" : "Expense"
            )
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (_, record) => (
                moment(_).format("HH:mm:ss A")
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => (
                record.type === "sale" ? "+" + record.amount : "-"+ record.amount
            )
        },
        {
            title: 'Type',
            key: 'type',
            render: (_, record) => (
                <span className={record.type === "sale" ? "text-success" : "text-primary"}>
                    {record.type}
                </span>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            type: 'sale',
            amount: 100,
            time: '2024-07-29 09:03:45 AM',
        },
        {
            key: '2',
            type: 'payment',
            amount: 42,
            time: '2024-07-29 08:41:40 AM',
        },
    ];
    return (
        <Row gutter={20}>
            <Col span={24} lg={12}>
                <Card className="px-0 pb-0 overview-chart" bordered={false}>
                    <div className="ant-card-head">
                        <div className="ant-card-head-wrapper">
                            <div className="ant-card-head-title">
                                Business Overview <br/>
                                <span className="text-success">$7.2141,997.5 (25%)</span>
                            </div>
                            <div className="ant-card-extra chart-options">
                                <Radio.Group defaultValue="all" buttonStyle="solid">
                                    <Radio.Button value="all">All</Radio.Button>
                                    <Radio.Button value="1m">1M</Radio.Button>
                                    <Radio.Button value="6m">6M</Radio.Button>
                                    <Radio.Button value="1y">1Y</Radio.Button>
                                    <Radio.Button value="ytd">YTD</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                    <div className={"chart-wrapper"}>
                        <Chart options={chart} series={series} type="area" />
                    </div>
                </Card>
            </Col>
            <Col span={24} lg={6} sm={12}>
                <Card bordered={false} title={"Balances"} className={"currency-card"}>
                    <h5 className={"currency-symbol"}>$ <span>Dollar</span></h5>
                    <h3 className={"text-primary dashboard-amount"}>9784.79</h3>
                    <img src={"/assets/images/cards.png"}/>
                </Card>
            </Col>
            <Col span={24} lg={6} sm={12}>
                <Card bordered={false} className={"calendar-card"}>
                    <Calendar fullscreen={false} headerRender={({value, type, onChange, onTypeChange}) => {
                        let month = value.get('month');
                        let date = moment(value.get('date') + '-' + (value.get('month') + 1) + '-' + value.get('year'), 'DD-MM-YYYY').format('MMMM YYYY');
                        return (
                            <div className={"calendar-header"}>
                                <button className="prev-month" onClick={() => {
                                    onChange(value.clone().month(month - 1))
                                }}>
                                    <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <p>{date}</p>
                                <button className="next-month"  onClick={() => {
                                    onChange(value.clone().month(month + 1))
                                }}>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                        )
                    }}/>
                </Card>
            </Col>
            <Col span={24} lg={12} className={"mt-20"}>
                <Card bordered={false} title={"Recent Activities"}>
                    <Table className={"dashboard-table"} showHeader={false} columns={columns} dataSource={data} pagination={false}></Table>
                </Card>
            </Col>
            <Col span={24} lg={12} className={"mt-20"}>
                <Card bordered={false} title={"References"}>
                    <List
                        className="references-list"
                        itemLayout="horizontal">
                        <List.Item
                            actions={[<a key="list-loadmore-edit">Details</a>]}
                        >
                            <List.Item.Meta
                                title="05 June"
                                description={<p><strong>Annual Retreat</strong>-Announcement concrning the public holidays..</p>}
                            />
                        </List.Item>
                        <List.Item
                            actions={[<a key="list-loadmore-edit">Details</a>]}
                        >
                            <List.Item.Meta
                                title="05 June"
                                description={<p><strong>Annual Retreat</strong>-Announcement concrning the public holidays..</p>}
                            />
                        </List.Item>
                    </List>

                </Card>
            </Col>
        </Row>
    )
}