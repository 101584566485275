import axios from 'axios';
import {API_BASE_URL} from "../configs/config";

let AuthService = {
    login: async (body) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/auth/login`, body)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                resolve(err.response.data);
            })
        });
    },

    register: async (body) => {
        let reqBody = {
            full_name: body.name,
            email: body.email,
            password: body.password,
            school: body.school
        }
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/auth/register`, reqBody)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                resolve(err.response.data);
            })
        });
    }
}

export default AuthService;