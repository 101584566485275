import {AuthLogo} from "../../components/AuthLogo";
import {Button, Col, Form, Input, Row} from "antd";
import "./auth.css";
import React from "react";

export function ForgotPassword() {
    return (
        <div className="auth-container">
            <Row justify="center" align="middle">
                <Col lg={10} md={16} sm={24}>
                    <div className="auth-card gradient-border">
                        <AuthLogo/>
                        <h2>Forgot Password</h2>
                        <Form
                            layout="vertical"
                            requiredMark={false}
                            onFinish={() => {}}
                        >
                            <Form.Item
                                hasFeedback
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Please enter your email!'}]}
                            >
                                <Input type="email" placeholder="Email" rootClassName="form-control"/>
                            </Form.Item>
                            <Button htmlType="submit" type="submit">Send Verification Code</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}