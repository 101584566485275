import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import UsersService from "../../../services/users.service"; // Import axios for making HTTP requests

const columns = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: 'School',
        dataIndex: 'school',
        sorter: (a, b) => a.school.localeCompare(b.school),
    },
    {
        title: 'Registered On',
        dataIndex: 'created_at',
        sorter: (a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateA - dateB;
        },
        render: (text) => {
            // Convert date string to desired format for display
            return new Date(text).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
    },
];

export function UserList() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Function to fetch data from API
        const fetchData = async () => {
            try {
                UsersService.allUsers().then(response => {
                    setUsers(response.data);

                });
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData(); // Call fetchData function

    }, []); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <Table columns={columns} dataSource={users} rowKey="id" loading={loading} />
    );
}
