import axios from 'axios';
import {ADMIN_BASE_URL} from "../configs/config";


let UsersService = {
    allUsers: async () => {
        return new Promise((resolve, reject) => {
            axios.get(`${ADMIN_BASE_URL}/users`)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                resolve(err.response.data);
            })
        });
    },
}

export default UsersService;