import './onboarding.css';
import React from "react";
import {Button, Col, Form, Input, InputNumber, Row} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";

export function Onboarding() {
    const [step, setStep] = React.useState(1);
    const [restaurant, setRestaurant] = React.useState('');
    const [burgerPrice, setBurgerPrice] = React.useState(0);
    const [domainName, setDomainName] = React.useState('');
    const [sqft, setSqft] = React.useState(0);
    const [employees, setEmployees] = React.useState(0);
    const [runningCost, setRunningCost] = React.useState(0);
    const baseRentRate = 2.67;
    const empHourlyRate = 10;
    const [totalRent, setTotalRent] = React.useState(0);
    const nextStepAuto = () => {
        if(step === 1)
            setStep(step + 1);
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }

    const onFinishRestaurant = (values) => {
        setRestaurant(values.name);
        nextStep();
    }

    const onFinishMenuItems = (values) => {
        setBurgerPrice(values.price);
        nextStep();
    }

    const onFinishDomainName = (values) => {
        setDomainName(values.name);
        nextStep();
    }

    const setSq = (sq) => {
        setSqft(sq);
        setTotalRent(sq * baseRentRate);
        switch (sq) {
            case 1000:
                setEmployees(2);
                setRunningCost(100);
                break;
            case 2000:
                setEmployees(2);
                setRunningCost(200);
                break;
            case 3000:
                setEmployees(4);
                setRunningCost(300);
                break;
            case 5000:
                setEmployees(5);
                setRunningCost(400);
                break;
            default:
                setEmployees(0);
                setRunningCost(0);
        }
        nextStep();
    }

    return (
        <div className={"onboarding-bg " + (step >= 5 ? "onboarding-bg2 " : "") + (step >= 11 ? "onboarding-bg3 " : "") + (step >= 19 ? "onboarding-bg4" : "")} onClick={nextStepAuto}>
            {step === 1 && <div className="onboarding-text gradient-border">
                Greetings, my name is Julian Kelly, and I am your virtual business consultant. I will guide you
                throughout this simulator and help you navigate through bottle necks.
            </div>}
            {step === 2 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Are you ready to embark a business journey of your life?
                </div>
                <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Let’s Go</Button>
                    </Col>
                </Row>
            </div>}
            {step === 3 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    And your gender?
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Male</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Female</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Not To Disclose</Button>
                    </Col>
                </Row>
            </div>}
            {step === 4 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Here is your welcome gift. We have opened your bank account, and credited $10,000 amount to initiate your business. Spend wisely and make profits.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0" htmlType="button">
                                <ArrowRightOutlined />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 5 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    That's all for now. Lets dive into your restaurant business
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0" htmlType="button">
                                <ArrowRightOutlined />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 6 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    First things first, we need to register your restaurant. Enter the name of your restaurant
                </div>
                <div className="onboarding-actions">
                    <Form layout="vertical"
                          requiredMark={false}
                          onFinish={onFinishRestaurant}
                          className="w-100"
                    >
                        <Row justify={"end"} align={"middle"} className="w-100">
                            <Col span={6}>
                                <Form.Item
                                    hasFeedback
                                    name="name"
                                    rules={[{required: true, message: 'Please enter restaurant name!'}]}
                                >
                                    <Input placeholder="Restaurant name" rootClassName="form-control"/>
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={3}>
                                <Button type="submit" className="arrow-button gradient-border m-0" htmlType="submit">
                                    <ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>}
            {step === 7 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Now for the sake of starting, we will open a sole proprietorship firm
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0" htmlType="button">
                                <ArrowRightOutlined />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 8 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    The licensing, registration and legal work of “{restaurant}” firm will cost $200
                </div>
                <div className="onboarding-actions">
                    <Row justify={"center"} align={"middle"} className="w-100">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Pay $200</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 9 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Congratulations on completing the legal part. Now you are the official owner of “{restaurant}” firm. Let us move to the next step of setting up our fast food restaurant.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0" htmlType="button">
                                <ArrowRightOutlined />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 10 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-idea.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Keep in mind the 7ps of marketing, that is product, price, place, promotion, people, process, and
                        physical evidence.<br/><br/>Read More</p>
                </div>
                <div className="onboarding-text gradient-border">
                    For the grand opening of our “{restaurant}” restaurant, we need to design a marketing strategy and
                    allocate budget to it
                </div>
                <div className="onboarding-actions">
                    <Row justify={"end"} align={"middle"} className="w-100">
                        <Col span={3}>
                            <Button onClick={nextStep} type="button" className="arrow-button gradient-border m-0"
                                    htmlType="button">
                                <ArrowRightOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 11 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-thinking.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Just note tha high sqft will result in higher rental, staff and operational cost. But will also drive more sales!<br/><br/>Choose Wisely</p>
                </div>
                <div className="onboarding-text gradient-border">
                    Choose how many sqft area you want to rent for your restaurant? The current rental is $2.67 Per Sqft. per month
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={() => setSq(1000)} className="onboarding-button gradient-border">1,000 Sqft</Button>
                            <Button onClick={() => setSq(2000)} className="onboarding-button gradient-border mt-20">2,000 Sqft</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setSq(3000)} className="onboarding-button gradient-border">3,000 Sqft</Button>
                            <Button onClick={() => setSq(5000)} className="onboarding-button gradient-border mt-20">5,000 Sqft</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 12 && <div className="w-100">
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Make Payment of ${totalRent}</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={prevStep} className="onboarding-button gradient-border">Rethink about decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 13 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    We will need at least {employees} employees to start with. The average wage here is ${empHourlyRate} per hour
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">Make Payment of
                                ${empHourlyRate * employees * 30 * 8} for month</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setStep(11)} className="onboarding-button gradient-border">Rethink about
                                decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 14 && <div className="w-100">
                <div className="onboarding-text-dialogue dialogue-right">
                    <p>The running cost include utilities, restocking, gas, maintenance, electricity, food and logistics among other monthly expenses. </p>
                </div>
                <div className="onboarding-text gradient-border">
                    Depending upon the choices made, the running cost of restaurant per month will be ${runningCost}.
                    The payment needs to be done at 1st of every month.
                </div>
                <div className="onboarding-actions">
                    <Row justify={"space-around"} align={"middle"} className="w-100 onboarding-actions">
                        <Col span={6}>
                            <Button onClick={nextStep} className="onboarding-button gradient-border">
                                Make Payment of ${runningCost}</Button>
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => setStep(11)} className="onboarding-button gradient-border">Rethink
                                about
                                decision!</Button>
                        </Col>
                    </Row>
                </div>
            </div>}
            {step === 15 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    For starter, we will only keep burger, fires, soda, and milkshake. Please enter the pricing you want for each of them
                </div>
                <div className="onboarding-actions">
                    <Form layout="vertical"
                          requiredMark={false}
                          onFinish={onFinishMenuItems}
                          className="w-100"
                    >
                        <Row justify={"space-between"} align={"middle"} className="w-100">
                            <Col span={6} offset={3}>
                                <Button htmlType={"button"} className="onboarding-button gradient-border">
                                    Burger</Button>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    hasFeedback
                                    name="price"
                                    className={"m-0"}
                                    rules={[{required: true, message: 'Please enter burger price'}]}
                                >
                                    <InputNumber placeholder="Burger price" min={0.1} step={"any"} rootClassName="form-control"/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button type="submit" className="arrow-button gradient-border m-0" htmlType="submit">
                                    <ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>}
            {step === 16 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Now we need to design a promotion strategy. For effective results, we need to identify different audience and target each of them. Select the audience you want to target first
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Gen Z & Early Adopters</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Millennials & working class </Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Gen X & Boomers</Button>
                    </Col>
                </Row>
            </div>}
            {step === 17 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-thumbs-up.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Remember this target audience is highly active on social media and get greatly influenced by those campaigns<br/><br/>Choose Wisely</p>
                </div>
                <div className="onboarding-text gradient-border">
                    Great choice, now let us design a campaign revolving around Gen Z. Select the strategy that you want
                    to adopt first
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Online
                            Marketing</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Offline
                            Marketing</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Hybrid
                            Campaign</Button>
                    </Col>
                </Row>
            </div>}
            {step === 18 && <div className="w-100">
                <div>
                    <img src="/assets/images/person-thumbs-up.png" alt="idea" className="onboarding-image-left"/>
                </div>
                <div className="onboarding-text-dialogue">
                    <p>Remember this target audience is highly active on social media and get greatly influenced by those campaigns<br/><br/>Choose Wisely</p>
                </div>
                <div className="onboarding-text gradient-border">
                    We need a strong digital presence for effective online marketing campaign. Choose and option that we want to establish first
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Make website & Business Profile</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Create social media handles</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Email Marketing Campaign</Button>
                    </Col>
                </Row>
            </div>}
            {step === 19 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Ok, for developing a website, we will need a domain name and a hosting space!
                </div>
                <div className="onboarding-actions">
                    <Form layout="vertical"
                          requiredMark={false}
                          onFinish={onFinishDomainName}
                          className="w-100"
                    >
                        <Row justify={"center"} align={"middle"} className="w-100">
                            <Col span={6}>
                                <Button htmlType={"button"} className="onboarding-button gradient-border">
                                    Domain name</Button>
                            </Col>
                            <Col span={6} offset={1}>
                                <Form.Item
                                    hasFeedback
                                    name="name"
                                    className={"m-0"}
                                    rules={[{required: true, message: 'Please enter burger price'}]}
                                >
                                    <Input placeholder="Enter Domain name" rootClassName="form-control"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"center"} align={"middle"} className="w-100 mt-20">
                            <Col span={6}>
                                <Button htmlType={"submit"} className="onboarding-button gradient-border">
                                    Pay $40 for a year!</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>}
            {step === 20 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Now we need to hire an independent contractor to develop our website
                </div>
                <Row justify={"space-between"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Hire a Fresher</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Hire Intermediate Resource</Button>
                    </Col>
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Hire Expert</Button>
                    </Col>
                </Row>
            </div>}
            {step === 21 && <div className="w-100">
                <div className="onboarding-text gradient-border">
                    Great choice, the {domainName} will complete the work in 20 days
                </div>
                <Row justify={"center"} align={"middle"} className="w-100 onboarding-actions">
                    <Col span={6}>
                        <Button onClick={nextStep} className="onboarding-button gradient-border">Pay $500</Button>
                    </Col>
                </Row>
            </div>}
        </div>
    )
}