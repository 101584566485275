import {Menu} from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import {AuthLogo} from "../AuthLogo";
import MenuItem from "antd/es/menu/MenuItem";

export function Sidebar(){
    const redirect = (url) =>{
        window.location.href = url;
    }
    return (<Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={"280"}
        onBreakpoint={(broken) => {

        }}
        onCollapse={(collapsed, type) => {

        }}
    >
        <div className="sider-logo text-center">
            <AuthLogo />
        </div>
        <Menu mode="inline">
            <MenuItem className="active" onClick={() => redirect("/dashboard")}><i className="fa-solid fa-table-cells-large"></i> Dashboard</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-regular fa-circle-user"></i> My Profile</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-coins"></i> Finance</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-wallet"></i> HR and Payroll</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-leaf"></i> IT & Marketing</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-gear"></i> Operations</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-comments"></i> Communications</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-gear"></i> Operations</MenuItem>
            <MenuItem onClick={() => redirect("/dashboard")}><i className="fa-solid fa-receipt"></i> Report</MenuItem>
        </Menu>
    </Sider>)
}