import {AuthLogo} from "../../components/AuthLogo";
import {Button, Col, Form, Input, Row} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "./auth.css";
import React from "react";
import AuthService from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../utils/UserContext";

export function Login() {
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();
    const { setUser } = React.useContext(UserContext);

    const onFinish = (values) => {
        setError(null);
        AuthService.login(values).then(response => {
            if(response.status) {
                localStorage.setItem('user', JSON.stringify(response.data));
                setUser(response.data);
                navigate('/dashboard');
            }
            else{
               setError(response.message);
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <div className="auth-container">
            <Row justify="center" align="middle">
                <Col lg={10} md={16} sm={24}>
                    <div className="auth-card gradient-border">
                        <AuthLogo/>
                        <h2>Login</h2>
                        <Form
                            layout="vertical"
                            requiredMark={false}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                hasFeedback
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Please enter your email!'}]}
                            >
                                <Input type="email" placeholder="Email" rootClassName="form-control"/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label="Password"
                                name="password"
                                rules={[{required: true, message: 'Please enter your password!'}]}
                            >
                                <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Password" rootClassName="form-control"/>
                            </Form.Item>

                            <a href="/forgot-password" className="text-white fs-12 fw-800">Forgot Password?</a>
                            <Button type="submit" htmlType="submit">Login</Button>
                            {error && <p className="text-danger fs-14 fw-800 text-center">{error}</p>}
                        </Form>
                        <p className="text-white fs-14 fw-800 text-center">Don’t have an account yet? <a href="/register" className="text-white">Register for Free</a></p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}